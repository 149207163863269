<template>
  <header
    class="CheckoutHeader header bg-lightest text-darkest sticky top-0 w-full z-header"
  >
    <div
      v-if="userStore.isBlockedCompany && !userStore.userProfile.impersonatingCompany"
      class="relative flex items-center justify-center h-32 w-full type-xs text-lightest bg-brightRed"
    >
      {{ $t('company.blocked') }}
    </div>

    <div class="shadow-bottomLine">
      <div class="flex h-[56px] items-center justify-between desk:layout-container">
        <div class="basis-1/3">
          <nuxt-link
            v-if="!isPointShop"
            :to="manualUrl('/')"
            class="mobOnly:pl-16 pr-8 flex"
            :external="true"
          >
            <img src="/icons/arrow-left.svg" class="h-24 w-24 desk:mr-12">
            <span class="hidden desk:inline type-headline-sm relative top-2">
              {{ $t('checkout.backToShopping') }}
            </span>
          </nuxt-link>
        </div>
        <div class="basis-1/3 text-center">
          <nuxt-link :to="manualUrl('/')" :external="true">
            <img
              :src="`/logos/${globalContent.getLogoUrl}.svg`"
              class="w-[74px] h-[15px] desk:w-[93px] desk:h-[20px] inline-block"
              alt="logo"
            >
          </nuxt-link>
        </div>
        <div class="basis-1/3">
          <!--        <button class="mobOnly:pr-32 pl-8 inline-block  desk:flex relative top-2" @click="uiStore.setShowCheckoutHelp(true)">
            <img src="/fontawesome/lightest/regular/headset.svg" class="h-18 desk:mr-12 relative desk:top-3">
            <span class="hidden desk:inline type-sm-medium">
              {{ $t('checkout.support') }}
            </span>
          </button>-->
        </div>
      </div>
    </div>

    <PointshopHead v-if="isPointShop" />
  </header>
</template>

<script setup lang="ts">
/**
 * Please note, the link to the frontpage has :external="true" to force a full load
 * This is because, if checkout is reloaded, normal navigation will fail
 */
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import PointshopHead from '~/components/checkout/PointshopHead.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';
const { manualUrl } = useLinkReplacer();
const globalContent = useGlobalContentStore();
const userStore = useUserStore();

const route = useRoute();

const isPointShop = computed(()=> {
  return route.name?.toString()?.endsWith('checkout-pointshop-basketId');
});
</script>

<style scoped lang="postcss">
.header {
  backdrop-filter: blur(15px);
}
</style>
